<template>
  <div>
    <PeriodOfConstructionFormField :choices="choices" v-on="$listeners" />
    <ServiceField ref="service" hidden v-on="$listeners" />
  </div>
</template>

<script>
import { Selectable } from 'chimera/all/components/models/Selectable'
import PeriodOfConstructionFormField from 'chimera/all/components/form/fields/periodOfConstruction/PeriodOfConstructionFormField.vue'
import ServiceField from 'chimera/all/components/form/fields/service/ServiceField.vue'
import { heatPumpDefaultBE } from 'chimera/heatPump/service'

export default {
  name: 'PeriodOfConstructionFormPart',

  components: {
    ServiceField,
    PeriodOfConstructionFormField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable(
          this.$i18n.t('field.period-of-construction.options.before-1975'),
          this.$i18n.t('field.period-of-construction.options.before-1975'),
          this.$i18n.t('field.period-of-construction.options.before-1975'),
        ),
        new Selectable(
          this.$i18n.t(
            'field.period-of-construction.options.between-1975-and-1990',
          ),
          this.$i18n.t(
            'field.period-of-construction.options.between-1975-and-1990',
          ),
          this.$i18n.t(
            'field.period-of-construction.options.between-1975-and-1990',
          ),
        ),
        new Selectable(
          this.$i18n.t(
            'field.period-of-construction.options.between-1990-and-2000',
          ),
          this.$i18n.t(
            'field.period-of-construction.options.between-1990-and-2000',
          ),
          this.$i18n.t(
            'field.period-of-construction.options.between-1990-and-2000',
          ),
        ),
        new Selectable(
          this.$i18n.t('field.period-of-construction.options.after-2000'),
          this.$i18n.t('field.period-of-construction.options.after-2000'),
          this.$i18n.t('field.period-of-construction.options.after-2000'),
        ),
      ],
    }
  },

  /**
   *
   */
  mounted() {
    this.$refs.service.setValue(heatPumpDefaultBE.id)
  },
}
</script>

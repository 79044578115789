<template>
  <div>
    <t-form ref="form" @submit.prevent>
      <PeriodOfConstructionFormPart
        ref="periodOfConstruction"
        @result="onResult"
        @change="onChange"
      />
    </t-form>

    <FormErrorMessages :error-messages="errorMessages" />
  </div>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import TypeOfInsulationPage from '~/pages/quotes-requests/type-disolement'
import TypeOfHeatingPage from '~/pages/quotes-requests/type-de-chauffage'
import PeriodOfConstructionFormPart from '~/components/form/parts/periodOfConstruction/PeriodOfConstructionFormPart.vue'

export default {
  name: 'PeriodOfConstructionFormStep',

  components: {
    FormErrorMessages,
    PeriodOfConstructionFormPart,
  },

  extends: AbstractFormStep,

  /**
   * Pass through the submit event given from FormModal
   */
  created() {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed() {
    this.$nuxt.$off('submit')
  },

  methods: {
    /**
     * Transition
     */
    transition() {
      let route

      const value = this.$store.getters['lead/getData'](
        'period-of-construction',
      )[0].value
      const skipStep =
        value === this.$t('field.period-of-construction.options.after-2000')
      if (skipStep) {
        // Clear insulation data, in case the user went back on that step.
        // Since we skip it, we don’t want to keep the data.
        this.$store.dispatch('lead/rm', 'type-of-insulation')
        route = TypeOfHeatingPage
      } else {
        route = TypeOfInsulationPage
      }

      this.routeTo(route)
    },
  },
}
</script>

<template>
  <FormModal title="Quand votre maison a-t-elle été construite ?">
    <template #body>
      <PeriodOfConstructionFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import PeriodOfConstructionFormStep from '~/components/form/steps/periodOfConstruction/PeriodOfConstructionFormStep'

export default {
  components: {
    PeriodOfConstructionFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Quand votre maison a-t-elle été construite ?',
      headDescription: 'Indiquez quand votre maison a été construite.',
      path: '/quotes-requests/maison-construite',
      progressValue: 15,
      checkoutStep: 1,
    }
  },
}
</script>
